import React from "react";
import "./index.css"; // Import CSS for styling

const ChaloInputField = ({
    label,
    placeholder,
    type = "text",
    value,
    onChange,
    name,
    disabled = false,
    id = '',
    error = false
}) => {
    return (
        <div className={`cInput-container ${disabled ? "cInput-field-disabled" : ""} ${error ? "border-red" : ""}`}> 
        {label && value &&(
            <label className="cInput-label" htmlFor={id}>
            {label} <span className="cRequired">*</span>
            </label>
        )}
        <input
            type={type}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            className={`cInput-field ${value ? "cInput-position" : ""}`}
            id={id}
            autoComplete="off"
        />
    </div>
    );
};

export default ChaloInputField;
