import React, {useState} from "react";
import { withRouter } from "react-router-dom";
import "./cameraOnBoarding.css";
import ChaloButton from "../../../../shared/widgets/chaloButton";
import ChaloTopNav from "../../../../shared/widgets/chaloTopNav";
import { upsertCameraMasterData } from "../../../../services/vehicleMasterService";
import ChaloLoader from "../../../../shared/widgets/chaloLoader";
import { AuthenticationService } from "../../../../services/authorization";

const CameraConfirmDetails = (props) => {
  const { location, history } = props;
  const data = location.state || {};
  const [isLoading,setIsLoading] = useState(false);
  const authService = AuthenticationService.getInstance();

  const addCamera = () => {
    setIsLoading(true)
    
    const fields = [
      "cameraId",
      "cameraBrand",
      "cameraType",
      "vehicleNo",
      "position",
      "serviceName",
      "liveFeed"
    ];
    
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key]) => fields.includes(key))
    );
    if(filteredData.hasOwnProperty("liveFeed")){
      filteredData['isActive'] = filteredData.liveFeed;
      delete filteredData.liveFeed;
    }
    if(data.simCardInfo && data.simCardInfo.simICCID){
      filteredData["simCardNo"] = data.simCardInfo.simICCID;
    }
    upsertCameraMasterData("camera", filteredData, null)
      .then((response) => {
        setIsLoading(false)
        let path = authService.isManager() ? "/vehicleMasterView/cameraSuccess"  : "/vehicleMaster/cameraSuccess"
          history.push({
            pathname: path,
            state: {
              message: "Camera added successfully",
              buttonText: "Okay",
              redirectUrl: "/vehicleMaster/camera"
            }
          });
    })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };
  

  return (
    <div className="camera-wrapper row">
      {isLoading && <ChaloLoader />}
      <div className="camera-details-container">
        <ChaloTopNav navContent="Confirmation details" />

        <div className="col-md-12 camera-detail-content">
          <div className="passed-message-container">
            <h4>RTMP live feed</h4>
            <p className={(data.liveFeed) ? "Passed" : "failed"}>
                {(data.liveFeed) ? "Active" : "InActive"}
            </p>
          </div>
          <div className="passed-message-container">
            <h4>SD card check</h4>
            <p className={data.sdCardInfo && data.sdCardInfo.sdCardPresent ? "Passed" : "failed"}>
              {data.sdCardInfo && data.sdCardInfo.sdCardPresent ? "Passed" : "Not Available"}
            </p>
          </div>

          <div className="camera-confirm-form">
            <h4>Camera details</h4>
            <div className="feild-detail">
              <h6>Camera id</h6>
              <p>{data.cameraId || "N/A"}</p>
            </div>
            <div className="feild-detail">
              <h6>Bus number</h6>
              <p>{data.vehicleNo || "N/A"}</p>
            </div>
            <div className="feild-detail">
              <h6>Position</h6>
              <p>{data.position || "N/A"}</p>
            </div>
            <div className="feild-detail">
              <h6>Service name</h6>
              <p>{data.serviceName || "N/A"}</p>
            </div>
            <div className="feild-detail">
              <h6>Camera Brand</h6>
              <p>{data.cameraBrand || "N/A"}</p>
            </div>
            <div className="feild-detail">
              <h6>Camera Type</h6>
              <p>{data.cameraType || "N/A"}</p>
            </div>
          </div>
        </div>

        <div className="col-md-12 live-feed-footer">
          <ChaloButton buttonText="Proceed" onClick={addCamera} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(CameraConfirmDetails);
