import React from "react";
import PropTypes from "prop-types";
import './index.css'
const ChaloTopNav = (props)=>(
    <div className="qr-navbar">
        <button className="back-button" onClick={() => window.history.back()}>
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <h3 className="navbar-heading">{props.navContent}</h3>
   </div>
);

ChaloTopNav.defaultProps = {
    className: '',
    navContent: '',
    onClick: ()=> {
    }
}
;
ChaloTopNav.propTypes = {
    className: PropTypes.string,
    navContent: PropTypes.string,
    onClick: PropTypes.func
};
export default ChaloTopNav;