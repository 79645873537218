import React from "react";
import { PageWrapper,TableWrapper } from "../../../../../shared/layout";
import {
  Search as SearchBar,
  DatePicker,
  DropdownWrapper,
  Paginator
} from "../../../../../shared/widgets";
import * as moment from "moment";
import { ButtonToolbar, Modal } from "react-bootstrap";
import { SelectFilter } from "../../../../../shared/widgets/selectFilter/selectFilter";
import { VehicleInOutEventsHeaderConfig } from "../../utils";
import {
  fetchVehicleInOutEventData,
  exportVehicleInOut,
} from "../../services/reportService";
import { CustomExport } from "../../views/customExport";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props";
import { connect } from "react-redux";
import { fetchVehicleMaster } from "../../../../../services/vehicleMasterService";
import { RegionSelector } from "../../../../../shared/components";
import { routes } from "../../../../../routing/routes";
import { EpochtoEpochTZ } from "../../../../../shared/utils";
import { dateFormat } from "../../../../../Constant";


class VehicleInOutEvent extends React.Component {
  constructor(props) {
    super(props);
    let m = moment(new Date());
    this.state = {
      // type of a report
      type: this.props.typeId,
      // filter dropdown options and controls
      cityId: null,
      selectedCity: null,
      cities: [],
      agencyId: null,
      selectedAgency: null,
      agencies: [],
      operatorId: null,
      selectedOperators: null,
      operators: [],
      depotId: null,
      selectedDepot: null,
      depots: [],
      // search control
      searchString: "",
      // table controls
      filteredData: [],
      pageNo: 0,
      pageSize: 10,
      currentPage: 0,
      totalPages: 0,
      numberOfItems: 0,
      totalItems: 0,
      // Modal controls
      showModal: false,
      backdrop: false,
      // export controls
      exportOptions: [
        {
          key: "default",
          value: "YesterDay",
        },
        {
          key: "custom",
          value: "Select Range",
        },
      ],
      startDate: "",
      endDate: "",
      selectedDate: m.subtract(1, "day"),
      placeHolder: "Search",
    };
    this.timeout = null;
  }

  componentDidMount() {
    // On Component Mount Region should be selected by default
    this.fetchMasterData("city", null);
  }

  exportVehicleInOutData = () => {
    this.props.updateLoader(true);
    exportVehicleInOut(this.state)
      .then((res) => {
        this.props.updateLoader(false);
      })
      .catch((err) => {
        if (err.status === 401 || err.message === "Invalid token") {
          console.log(err);
        }
        this.props.updateLoader(false);
      });
  }

   handleSearchChange = (key) => {
    clearInterval(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState(
        {
          searchString: key,
          pageNo:0
        },
        () => {
          this.fetchVehicleInOutEventTableData();
        }
      );
    }, 500);
  }

 
  dateChange = (date) => {
    let startDate = parseInt(date.startOf("day").format("x"));
    let endDate = parseInt(date.endOf("day").format("x")) + 1;
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        selectedDate: date,
        pageNo:0
      },
      () => {
       this.fetchVehicleInOutEventTableData();
      }
    );
  }

  fetchVehicleInOutEventTableData = ()=>  {
    this.props.updateLoader(true);
    fetchVehicleInOutEventData(this.state)
      .then((data) => {
        this.setState({
          filteredData: data.items,
          totalPages: data.totalPages,
          pageNo: data.currentPage,
          totalItems: data.totalItems,
        });
        this.props.updateLoader(false);
      })
      .catch((err) => {
        this.props.updateLoader(false);
        this.setState({
            filteredData: [],
            pageNo: 0,
            pageSize: 0,
            currentPage: 0,
            totalPages: 0,
            numberOfItems: 0,
            totalItems: 0,
            // searchString:""
        });
        if (err.status === 401 || err.message === "Invalid token") {
          this.props.setTokenExpired(true);
        }
      });
  }
  getList = (pageNo)=> {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.setState(
        {
          pageNo,
        },
        () => {
         this.fetchVehicleInOutEventTableData();
        }
      );
    }
  }
  open = (data) => {
    this.setState({
      showModal: true,
    });
  }

  /**
   * Used to show Modal for "Add new device" or "Update device"
   */
  displayModal = ()=>  {
    return (
      <Modal
        dialogClassName="custom-range-modal"
        show={this.state.showModal}
        onHide={this.close.bind(this)}
        backdrop={this.state.backdrop}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.showModal ? "Select Range" : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.showModal && (
            <CustomExport
              type={this.props.typeId}
              state={this.state}
              onComplete={(e, msg) => {
                this.props.initiateNotification({
                  type: e,
                  message: msg,
                });
                this.close();
              }}
              onClose={this.close.bind(this)}
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }

  /**
   * Call when Add/Update modal Close.
   */
  close = ()=>  {
    this.setState({
      showModal: false,
    });
  }

  fetchMasterData = (masterType, masterParentId) => {
    fetchVehicleMaster(masterType, masterParentId, 0, 1000)
      .then((res) => {
        switch (masterType) {
          case "city": {
            this.setState({ cities: res.content });
            break;
          }
          case "agency": {
            this.setState({ agencies: res.content });
            break;
          }
          case "operator": {
            this.setState({ operators: res.content });
            break;
          }
          case "depot": {
            this.setState({ depots: res.content });
            break;
          }
        }
      })
      .catch((err) => {
        this.errorHandler(err);
      });
  }
  onFilterChange = (value, masterType) => {
    const updateStateAndFetchData = (stateProps, fetchData) => {
      this.setState(stateProps, fetchData);
    };
    
    const getNameOrId = (obj,col)=>{
      if(obj && obj[col]){
        return obj[col]
      }
      return null;
    }
  
    switch (masterType) {
      case "city": {
        const selectedCity = getNameOrId(value, "name");
        const cityId = getNameOrId(value, "id");
        const agencies = [];
        const selectedAgency = null;
        const agencyId = null;
        const depotId = null;
        const selectedDepot = null;
        const depots = [];
        const operatorId = null;
        const operators = [];
        const searchString = "";
  
        const stateProps = {
          selectedCity,
          cityId,
          agencies,
          selectedAgency,
          agencyId,
          depotId,
          selectedDepot,
          depots,
          operatorId,
          operators,
          searchString,
        };
  
        const fetchData = () => {
          this.fetchVehicleInOutEventTableData();
          if (cityId) {
            this.fetchMasterData("agency", { cityId });
            this.fetchMasterData("operator", { cityId });
            this.fetchMasterData("depot", { cityId });
          }
        };
  
        updateStateAndFetchData(stateProps, fetchData);
        break;
      }
      case "agency": {
        const selectedAgency = getNameOrId(value, "name");
        const agencyId = getNameOrId(value, "id");
  
        const stateProps = {
          selectedAgency,
          agencyId,
        };
  
        const fetchData = () => {
          this.fetchVehicleInOutEventTableData();
        };
  
        updateStateAndFetchData(stateProps, fetchData);
        break;
      }
      case "operator": {
        const selectedOperator = getNameOrId(value, "name");
        const operatorId = getNameOrId(value, "id");
  
        const stateProps = {
          selectedOperator,
          operatorId,
        };
  
        const fetchData = () => {
          this.fetchVehicleInOutEventTableData();
        };
  
        updateStateAndFetchData(stateProps, fetchData);
        break;
      }
      case "depot": {
        const selectedDepot = getNameOrId(value, "name");
        const depotId = getNameOrId(value, "id");
  
        const stateProps = {
          selectedDepot,
          depotId,
        };
  
        const fetchData = () => {
          this.fetchVehicleInOutEventTableData();
        };
  
        updateStateAndFetchData(stateProps, fetchData);
        break;
      }
    }
  };

  
  handleRegionChange = (regionId)=> {
    this.setState((prevState) => {
      let startDate = parseFloat(prevState.selectedDate.startOf("day").format("x"));
      let endDate = parseFloat(prevState.selectedDate.endOf("day").format("x")) + 1;
  
      return {
        regionId,
        startDate,
        endDate,
        selectedProtocol: null,
        selectedCity: null,
        selectedAgency: null,
        selectedOperator: null,
        selectedDepot: null,
      };
    }, () => {
      this.fetchVehicleInOutEventTableData();
    });
  };
  

  filterByProtocol = (value)=> {
    let selectedProtocol = value;
    if (selectedProtocol == "reset") {
      selectedProtocol = null;
    }
    this.setState(
      {
        selectedProtocol: selectedProtocol,
      },
      () => {
        if (value) this.fetchVehicleInOutEventTableData();
      }
    );
  }

  removeGMT(date) {
    var len = date.length;
    return date.slice(12, len).toUpperCase();
  }

  getColumn = (row,key)=>{
    const getTitleCase = (str) => {
      return str.replace(/\b\w/g, (char) => char.toUpperCase());
    }
    const dateFields = ['timestamp'];
    const statusCol = ['status'];
    let displayValue = 'NA';
    if(dateFields.includes(key) && row[key]){
      displayValue = this.removeGMT( moment( new Date( EpochtoEpochTZ( parseInt(row[key]), localStorage.getItem("timeZone") ) ) ).format(dateFormat) );
    }else if(statusCol.includes(key) && row[key]){
      displayValue = getTitleCase(row[key]);
    }else if(row[key]){
      displayValue = row[key];
    }
    return (
    <td title={displayValue}>
      {displayValue}         
    </td>
    )
  }

  render() {
    return (
      <div>
        <div className="report-section">
          <PageWrapper.Header heading="Vehicle In Out Event" hideS={true}>
            {this.state.filteredData && (
              <span className="pl-3">
                {" "}
                Device Count : {this.state.totalItems}
              </span>
            )}
            <div className="pull-right">
              <DropdownWrapper
                title="Export"
                menue={this.state.exportOptions}
                onSelect={(value, subValue) => {
                  switch (value) {
                    case "default": {
                      this.exportVehicleInOutData();
                      break;
                    }
                    case "custom": {
                      this.open(this, null);
                      break;
                    }
                  }
                }}
              ></DropdownWrapper>
              {this.displayModal()}
            </div>
            <div className="pull-right search-container custom-search">
              <SearchBar
                placeHolder={this.state.placeHolder}
                onSearchChange={this.handleSearchChange}
                value={this.state.searchString}
              />
            </div>
            <div
              className="dataTables_length wrapper-gps"
              id="dataTables-example_length"
            >
              <ButtonToolbar>
                <DatePicker
                  date={this.state.selectedDate}
                  onDateChange={this.dateChange}
                  withoutDefaultBorder={false}
                />
              </ButtonToolbar>
            </div>
          </PageWrapper.Header>
          <PageWrapper.Body>
            <PageWrapper.BodyHeader>
              <div className="header-button-container ">
                <div className="form-group">
                  <div
                    className="dataTables_length wrapper-gps"
                    id="dataTables-example_length"
                  >
                    <ButtonToolbar>
                      <p className="filters-label">Filter By:</p>
                      <RegionSelector
                        onRegionChange={this.handleRegionChange}
                        filterKey={routes.REPORT}
                        showProtocols={false}
                        onProtocolChange={this.filterByProtocol}
                        selectedProtocol={this.state.selectedProtocol}
                      />
                      <SelectFilter
                        value={this.state.selectedCity}
                        onChange={(value) => {
                          this.onFilterChange(value, "city");
                        }}
                        clearable={true}
                        valueKey="name"
                        labelKey="name"
                        placeholder="City"
                        options={this.state.cities}
                      />
                      <SelectFilter
                        value={this.state.selectedAgency}
                        onChange={(value) => {
                          this.onFilterChange(value, "agency");
                        }}
                        clearable={true}
                        valueKey="name"
                        labelKey="name"
                        placeholder="Agency"
                        options={this.state.agencies}
                        disabled={!this.state.selectedCity}
                      />
                      <SelectFilter
                        value={this.state.selectedOperator}
                        onChange={(value) => {
                          this.onFilterChange(value, "operator");
                        }}
                        clearable={true}
                        valueKey="name"
                        labelKey="name"
                        placeholder="Operator"
                        options={this.state.operators}
                        disabled={!this.state.selectedCity}
                      />
                      <SelectFilter
                        value={this.state.selectedDepot}
                        onChange={(value) => {
                          this.onFilterChange(value, "depot");
                        }}
                        clearable={true}
                        valueKey="name"
                        labelKey="name"
                        placeholder="Depot"
                        options={this.state.depots}
                        disabled={!this.state.selectedCity}
                      />
                    </ButtonToolbar>
                  </div>
                </div>
              </div>
            </PageWrapper.BodyHeader>
            <TableWrapper>
              <TableWrapper.Header
                columns={VehicleInOutEventsHeaderConfig}
                timeInterval={this.props.timeInterval}
                timeParse={this.props.timeParse}
                onSortToggle={this.handleDataSorting}
                type={"health"}
              />
              <tbody>
                {this.state.filteredData &&
                  this.state.filteredData.map((row) => {
                    return (
                      <tr key={row.id} className="cursor-pointer">
                        {Object.keys(VehicleInOutEventsHeaderConfig).map(
                          (key) => {
                            return (
                              this.getColumn(row,key)
                            );
                          }
                        )}
                      </tr>
                    );
                  })}

                {(!this.state.filteredData ||
                  (this.state.filteredData &&
                    this.state.filteredData.length == 0)) && (
                  <tr className="gradeA odd noDataStyle" role="row">
                    <td className="sorting_1" colSpan="6">
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            </TableWrapper>
            {this.state.filteredData && (
              <Paginator
                pageNo={this.state.pageNo}
                totalPages={this.state.totalPages}
                getList={this.getList}
              />
            )}
            <br />
          </PageWrapper.Body>
        </div>
      </div>
    );
  }
}

export default connect(null, defaultMapDispatchToProps)(VehicleInOutEvent);
