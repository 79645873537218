import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import ChaloButton from "../../../../shared/widgets/chaloButton";
import ChaloTopNav from "../../../../shared/widgets/chaloTopNav";
import ChaloInputField from "../../../../shared/widgets/chaloInputField";
import ChaloDropDown from "../../../../shared/widgets/chaloDropDown";
import "./cameraOnBoarding.css";
import { validateCameraDetails ,fetchCameraPositionData,fetchCameraServiceNames, fetchCameraBrands } from "../../../../services/vehicleMasterService";
import ChaloLoader from "../../../../shared/widgets/chaloLoader";
import { AuthenticationService } from "../../../../services/authorization";

const CameraDetails = ({ history, location }) => {
    const feedData = (location && location.state) || {};
    const [isLoading,setIsLoading] = useState(false);
    const authService = AuthenticationService.getInstance();

    const [formState, setFormState] = useState({
        busNumber: "",
        position: "",
        cameraType: "Trueview webcast RTC ( RTMP )",
        serviceName: "Live Feed",
        cameraBrand: "TrueView"
    });


    const [isFormValid, setIsFormValid] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({});

    useEffect(() => {
        setIsFormValid(
            formState.busNumber.trim() !== "" && 
            formState.position.trim() !== "" && 
            formState.cameraType.trim() !== "" &&
            formState.serviceName.trim() !== "" &&
            formState.cameraBrand.trim() !== ""
        );
        setFieldErrors({});
            
    }, [formState]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormState((prev) => ({ ...prev, [name]: value }));
    };
    const [positionOptions, setPositionOptions] = useState([]);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [cameraBrand, setCameraBrand] = useState([]);

    const handleNavigation = useCallback(async () => {
        setIsLoading(true);
        const payload = {
            cameraId: feedData.cameraId,
            cameraBrand: formState.cameraBrand,
            cameraType: formState.cameraType,
            vehicleNo: formState.busNumber,
            position: formState.position,
            serviceName: formState.serviceName,
        };
        try {
            const response = await validateCameraDetails(JSON.stringify(payload));
            if(response.status == "SUCCESS"){
                setIsLoading(false);
                history.push({
                    pathname: authService.isManager() ? "/vehicleMasterView/cameraConfirmDetails" : "/vehicleMaster/cameraConfirmDetails",
                    state: { ...feedData, ...payload}
                });
            }else if(response.status == "FAILURE"){
                setIsLoading(false);
                setFieldErrors(response.error);
            }
        } catch (error) {
            setIsLoading(false);

            if (error && error.status === "FAILURE" && error.error) {
                setFieldErrors(error.error);
            } else {
                setFieldErrors({ general: "An unexpected error occurred. Please try again." });
            }
        }
    }, [history, feedData, formState]);
    

    useEffect(() => {
        const getDropdownData = async () => {
            try {
                const [positions, services,brands] = await Promise.all([
                fetchCameraPositionData(),
                fetchCameraServiceNames(),
                fetchCameraBrands(),
                ]);
                setPositionOptions(
                    positions.map((item) => ({ label: item, value: item }))
                );
                setServiceOptions(
                    services.map((item) => ({ label: item, value: item }))
                );
                setCameraBrand(
                    brands.map((item) => ({ label: item, value: item }))
                )
            } catch (error) {
                console.error("Error fetching dropdown data:", error);
            }
        };
        
            getDropdownData();
      }, []);

    const cameraType = useMemo(() => [
        { label: "Trueview webcast RTC ( RTMP )", value: "Trueview webcast RTC ( RTMP )" },
    ], []);

    return (
        <div className="camera-wrapper row">
            {isLoading && <ChaloLoader />}
                <div className="camera-details-container">
                <ChaloTopNav navContent="Required information" />

                <div className="col-md-12 camera-detail-content">
                    <div className="passed-message-container">
                        <h4>SD card check</h4>
                        <p className={(feedData.sdCardInfo && feedData.sdCardInfo.sdCardPresent) ? "Passed" : "failed"}>
                            {(feedData.sdCardInfo && feedData.sdCardInfo.sdCardPresent) ? "Passed" : "Not Available"}
                        </p>
                    </div>
                    <div className="camera-detail-form">
                        <h4>Camera details</h4>

                        <div className="chalo-input-field">
                            <ChaloInputField
                                label="Camera id"
                                value={feedData.cameraId || ""}
                                placeholder="Camera id"
                                disabled
                                id="cameraId"
                            />
                        </div>

                        <div className="chalo-input-field">
                            <ChaloInputField
                                label="Bus number"
                                placeholder="Bus number *"
                                name="busNumber"
                                value={formState.busNumber}
                                onChange={handleChange}
                                id="busNumberId"
                                error = {fieldErrors.vehicleNo}
                            />
                            {fieldErrors.vehicleNo && (
                                <div className="camera-error-text">{fieldErrors.vehicleNo}</div>
                            )}
                        </div>

                        <div className="chalo-input-field">
                            <ChaloDropDown
                                label="Position"
                                options={positionOptions}
                                value={formState.position}
                                name="position"
                                placeholder="Position *"
                                onChange={handleChange}
                                id="position-id-unique"
                                error={fieldErrors.position}
                            />
                            {fieldErrors.position && (
                                <div className="camera-error-text">{fieldErrors.position}</div>
                            )}
                        </div>

                        <div className="chalo-input-field">
                            <ChaloDropDown
                                label="Service name"
                                options={serviceOptions}
                                value={formState.serviceName}
                                name="serviceName"
                                placeholder="Service name *"
                                onChange={handleChange}
                                id="serviceName"
                                disabled
                                error={fieldErrors.serviceName}
                            />
                            {fieldErrors.serviceName && (
                                <div className="camera-error-text">{fieldErrors.serviceName}</div>
                            )}
                        </div>

                        <div className="chalo-input-field">
                            <ChaloDropDown
                                label="Camera brand"
                                options={cameraBrand}
                                value={formState.cameraBrand}
                                name="cameraBrand"
                                placeholder="Camera Brand *"
                                onChange={handleChange}
                                id="camera-brand-id-unique"
                                disabled
                            />
                        </div>

                        <div className="chalo-input-field">
                            <ChaloDropDown
                                label="Camera type"
                                options={cameraType}
                                value={formState.cameraType}
                                name="cameraType"
                                placeholder="Camera type *"
                                onChange={handleChange}
                                id="cameraType"
                                disabled
                            />
                        </div>
                        
                    </div>
                </div>
                
                <div className="col-md-12 live-feed-footer">
                    <ChaloButton buttonText="Submit" onClick={handleNavigation} disabled={!isFormValid} />
                </div>
            </div>
        </div>
    );
};

CameraDetails.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default withRouter(CameraDetails);
