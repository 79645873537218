import React from "react";
import { Button } from "react-bootstrap";
import Toggle from "react-toggle";
import "react-toggle/style.css";

import { CityFilterDropDown } from "../../../../../shared/components/cityFilterDropDown/CityFilterDropDown";
import { getAllLanguages,addLanguagesToCity,deleteLanguageWithCity } from "../../../../../services/vehicleMasterService";
import { SelectFilter } from "../../../../../shared/widgets";
import { MessageType } from "../../../../../Constant";
import "./index.css";


export class CreateLanguageWithCity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCity: null,
      selectedLanguage: [],
      languageOptions: [],
      allLangSelected: false,
      tableHeadings: [
        "Language",
        "Default Print Language",
        "Default UI Language",
      ],
      upsertLoading: false,
      deleteLoading: false,
    };

    this.cityFilterProps = {
      rowClass: "row",
      cityLabelClass: "col-md-3",
      cityOptionClass: "col-md-9",
      selectedCity: this.state.selectedCity,
      setSelectedCity: (value)=>{
        this.setState({selectedCity: value})
      },
    };
  }

  setLanguages = (languages) => {
    languages.forEach((language) => {
      language["defaultUI"] = false;
      language["defaultPrint"] = false;
    });
    this.setState({ languageOptions: languages });
  };

  getLangOptions = () => {
    getAllLanguages().then((languages) => {
      this.setLanguages(languages);
    });
  };

  fillSelectedLangForEditMode = () => {
    const {serverRow} = this.props;
    const data = [];
    this.props.serverRow.languages.forEach((row) => {
      data.push({
        ...row,
        defaultPrint: row.languageId == serverRow.defaultPrintLanguageId,
        defaultUI: row.languageId == serverRow.defaultUILanguageId,
      });
    });
    this.setState({
      selectedCity: { cityId: serverRow.cityId },
      selectedLanguage: data,
    });
  };

  getLangDropDownAndFill = () => {
    this.getLangOptions();
    if (this.props.editMode) {
      this.fillSelectedLangForEditMode();
      if (!this.state.tableHeadings.includes("Action")) {
        this.setState((prevState) => {
          return {
            ...prevState,
            tableHeadings: [...prevState.tableHeadings, "Action"],
          };
        });
      }
    }
  };

  componentDidMount() {
    this.getLangDropDownAndFill();
  }

  componentDidUpdate(prevProps) {

    if (
      prevProps.serverRow && this.props.serverRow && 
      prevProps.serverRow.languages.length !==
      this.props.serverRow.languages.length
    ) {
      this.getLangDropDownAndFill();
    }
  }

  markToggle = (value, key) => {
    const { selectedLanguage } = this.state;
    const { initiateNotification } = this.props;
    const isDefaultPrintLanguageId = selectedLanguage.find(
      (lang) => lang.defaultPrint
    );
    const isDefaultUILanguageId = selectedLanguage.find(
      (lang) => lang.defaultUI
    );
    if (
      isDefaultPrintLanguageId &&
      key == "defaultPrint" &&
      isDefaultPrintLanguageId.languageId != value.languageId
    ) {
      initiateNotification({
        type: MessageType.ERROR,
        message: "Only one language can be default Print",
      });
      return;
    }
    if (
      isDefaultUILanguageId &&
      key == "defaultUI" &&
      isDefaultUILanguageId.languageId != value.languageId
    ) {
      initiateNotification({
        type: MessageType.ERROR,
        message: "Only one language can be default UI",
      });
      return;
    }
    const newSelectedLanguages = selectedLanguage.map((lang) => {
      let flag = lang.languageId == value.languageId;
      if (
        flag &&
        isDefaultPrintLanguageId &&
        key == "defaultPrint" &&
        isDefaultPrintLanguageId.languageId == value.languageId
      ) {
        flag = false;
      }
      if (
        flag &&
        isDefaultUILanguageId &&
        key == "defaultUI" &&
        isDefaultUILanguageId.languageId == value.languageId
      ) {
        flag = false;
      }
      return {
        ...lang,
        [key]: flag,
      };
    });
    this.setState({
      selectedLanguage: newSelectedLanguages,
    });
  };

  deleteRow = (row, isMultiDelete) => {
    const {
      initiateNotification,
      onComplete,
      serverRow,
      synLanguageTableAfterIndividualLangRemove,
    } = this.props;
    let languageIds = "";
    if (isMultiDelete) {
      languageIds = this.state.selectedLanguage
        .map((el) => el.languageId)
        .join();
    } else {
      languageIds = row.languageId;
    }
    this.setState({ deleteLoading: true });
    deleteLanguageWithCity(serverRow.cityId, languageIds)
      .then(() => {
        if (isMultiDelete) {
          onComplete(MessageType.SUCCESS, "Deleted Successfully!");
        } else {
          initiateNotification({
            type: MessageType.SUCCESS,
            message: "Deleted Successfully!",
          });
          synLanguageTableAfterIndividualLangRemove();
        }
        this.setState({ deleteLoading: false });
      })
      .catch((err) => {
        this.setState({ deleteLoading: false });
        onComplete(
          MessageType.ERROR,
          err.description ? err.description : "Error occurred "
        );
      });
  };

  onSave = () => {
    const { selectedLanguage,selectedCity } = this.state;
    const { onComplete, editMode } = this.props;
    const languagesRequestDto = selectedLanguage.map((item) => {
      return {
        languageId: item.languageId,
        defaultPrint: item.defaultPrint,
        defaultUI: item.defaultUI,
      };
    });
    if (selectedCity) {
      this.setState({ upsertLoading: true });
      addLanguagesToCity(
        { cityId: selectedCity.cityId, languagesRequestDto },
        editMode
      )
        .then(() => {
          onComplete(MessageType.SUCCESS, "Successfully done ");
          this.setState({ upsertLoading: false });
        })
        .catch((err) => {
          this.setState({ upsertLoading: false });
          onComplete(
            MessageType.ERROR,
            err.description ? err.description : "Error occurred "
          );
        });
    }
  };

  onSelectAllChange = () => {
    const { allLangSelected, languageOptions } = this.state;
    const { editMode, serverRow } = this.props;
    this.setState((prev) => {
      return {
        ...prev,
        allLangSelected: !prev.allLangSelected,
      };
    });
    if (!allLangSelected) {
      let options = [];
      if (editMode) {
        options = languageOptions.map((option) => {
          return {
            ...option,
            defaultPrint: option.languageId == serverRow.defaultPrintLanguageId,
            defaultUI: option.languageId == serverRow.defaultUILanguageId,
          };
        });
      } else {
        options = languageOptions;
      }
      this.setState({ selectedLanguage: options });
    } else {
      this.setState({ selectedLanguage: [] });
    }
  };

  getUpsertBtntext = (mode) => {
    const { upsertLoading } = this.state;
    if (mode) {
      return upsertLoading ? "Updating..." : "Update";
    }
    return upsertLoading ? "Creating..." : "Create";
  };

  isNewlyAdded = (item) => {
    return this.props.serverRow.languages.some(
      (lang) => lang.languageId == item.languageId
    );
  };

  isAtleasetOneDefaultLangSelected = () => {
    const defaultPrintSelected =  this.state.selectedLanguage.some(
      (lang) => lang.defaultPrint
    );
    const defaultUISelected =  this.state.selectedLanguage.some(
      (lang) => lang.defaultUI
    );
    return defaultPrintSelected && defaultUISelected
  };

  render() {
    const { editMode, onClose } = this.props;
    const {
      allLangSelected,
      languageOptions,
      selectedLanguage,
      tableHeadings,
      deleteLoading,
      selectedCity,
      upsertLoading,
    } = this.state;
    this.cityFilterProps = {
      rowClass: "row",
      cityLabelClass: "col-md-3",
      cityOptionClass: "col-md-9",
      selectedCity: this.state.selectedCity,
      setSelectedCity: (value)=>{
        this.setState({selectedCity: value})
      },
    }
    return (
      <div
        className={
          editMode
            ? "edit-language create-language-with-city"
            : "create-language-with-city"
        }
      >
        {editMode}
        {!editMode && <CityFilterDropDown {...this.cityFilterProps} />}
        <br />
        <div className="row">
          <div className="col-md-3">
            <label htmlFor="languages" className="form-label">
              <span>Languages</span>
            </label>
          </div>
          <div className="col-md-9">
            <div className={allLangSelected ? "language-drop-down-height" : ""}>
              <SelectFilter
                value={selectedLanguage}
                type="city"
                onChange={(value) => {
                  if (!value.length) {
                    this.setState({ allLangSelected: false });
                    this.setLanguages(languageOptions);
                  }
                  this.setState({
                    selectedLanguage: value,
                  });
                }}
                valueKey="languageId"
                labelKey="language"
                clearable={true}
                placeholder="Select Language"
                options={languageOptions}
                multi
              />
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={allLangSelected}
                onChange={this.onSelectAllChange}
              />
              <span>Select All</span>
            </div>
          </div>
        </div>
        <br />
        { selectedLanguage.length > 0 && (
          <div className="delete-btn-container">
            <div> <span>Note : </span> One default print and one default UI language are required. </div>
            {editMode && <Button
              bsStyle="primary"
              className="delete-btn"
              onClick={() => this.deleteRow({}, true)}
              disabled={deleteLoading}
            >
              Delete All
            </Button>}
          </div>
        )}
        <div className="table-heading table-header">
          {selectedLanguage.length > 0 &&
            tableHeadings.map((tableHeading) => {
              return <span key={tableHeading}>{tableHeading}</span>;
            })}
        </div>

        <div className="scroll-container">
          {selectedLanguage &&
            selectedLanguage.length > 0 &&
            selectedLanguage.map((item, index) => {
              return (
                <div key={index} className="table-heading table-row">
                  <p className="selected-language-name">{item.language}</p>
                  <label>
                    <Toggle
                      checked={item.defaultPrint}
                      className="custom-toggle"
                      icons={false}
                      onChange={() => {
                        this.markToggle(item, "defaultPrint");
                      }}
                    />
                  </label>
                  <label>
                    <Toggle
                      checked={item.defaultUI}
                      className="custom-toggle"
                      icons={false}
                      onChange={() => {
                        this.markToggle(item, "defaultUI");
                      }}
                    />
                  </label>

                  {editMode && this.isNewlyAdded(item) && (
                    <div
                      className="delete-icon-container"
                      onClick={() => this.deleteRow(item, false)}
                      onKeyDown={() => {}}
                    >
                      <DeleteIconSvg />
                    </div>
                  )}
                  {editMode && !this.isNewlyAdded(item) && (
                    <p className="delete-icon-container-empty">
                      <span></span>
                    </p>
                  )}
                </div>
              );
            })}
        </div>
        <div className="action-button">
          <Button
            bsStyle="primary"
            onClick={this.onSave}
            disabled={
              !(selectedCity && selectedLanguage.length > 0) || upsertLoading || !this.isAtleasetOneDefaultLangSelected()
            }
          >
            {this.getUpsertBtntext(editMode)}
          </Button>
          &nbsp;
          <Button onClick={() => onClose()}>Close</Button>
        </div>
      </div>
    );
  }
}

const DeleteIconSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="14"
      viewBox="0 0 448 512"
    >
      <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
    </svg>
  );
};
