import React from "react";
import PropTypes from "prop-types";
import './index.css'
const ChaloButton = (props)=>(
<button className='chalo-button-container'
    onClick={props.onClick} 
    disabled={props.disabled}
    style={{ opacity: props.disabled ? "40%" : "100%" }}
 >
    <a className="button">
        <span>{props.buttonText}</span>
    </a>
</button>

);

ChaloButton.defaultProps = {
    className: '',
    buttonText: '',
    disabled: false,
    onClick: ()=> {
    }
}
;
ChaloButton.propTypes = {
    className: PropTypes.string,
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};
export default ChaloButton;