import React from "react";
import { Button } from "react-bootstrap";
import { MessageType, validationRegex, idLengthLimit, BASE_URL } from "../../../../../Constant";
import validateOperator from "../../validator/operatorValidator";
import Select from "react-select";
// import { fetchVehicleMaster, createMasterData, fetchIdType } from "../../../../../services/vehicleMasterService";
import { connect } from "react-redux";
import { defaultMapDispatchToProps } from "../../../../../redux/map-to-props";
import { fetchVehicleMaster, createMasterData, fetchIdType, verifyId, verifyBank, verifyPAN, fetchImgaes } from "../../../../../services/vehicleMasterService";
import { userAuthoritiesArray }  from "../../../../../services/authorization/Authorization";
import { Authorities } from "../../../../../services/authorization";

const SUPPORTED_TYPE = ['jpg', 'jpeg', 'png'];
export class OperatorCreate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {},
            stayOpen: false,
            removeSelected: true,
            rtl: false,
            disabled: false,
            citiesOptions: [],
            selectedCity: null,
            validEmail: true,
            validGst: true,
            validIfsc: true,
            validPancard: true,
            validOperatorName: true,
            validId: true,
            proofPan: null,
            proofBank: null,
            proofId: null,
            operatorTypeOptions: [{ label: 'Individual', value: 'Individual' }, { label: 'Company', value: 'Company' }],
            accountTypeOptions: [{ label: 'Savings', value: 'Savings' },
            { label: 'Current', value: 'Current' }, { label: 'Overdraft', value: 'Overdraft' }
            ],

        };
        this.onCityChange = this.onCityChange.bind(this);
        this.isAdmin = userAuthoritiesArray()[0]===Authorities.Admin;


    }

    filInitialState() {
        this.updateFormErrorState();
        this.fetchMasterData('city', null);
        this.fetchIdTypeData();
        if (this.props.updateSelection) {
            let data = this.props.updateSelection
            this.setState({
                operator: data.name ? data.name : '',
                phone: data.phone ? data.phone : '',
                address: data.address ? data.address : '',
                noOfBuses: data.noOfBuses ? data.noOfBuses : '',
                gstCertificate: data.gstCertificate ? data.gstCertificate : '',
                idNumber: data.idNumber ? data.idNumber : '',
                panCard: data.panCard ? data.panCard : '',
                accountHolderName: data.accountHolderName ? data.accountHolderName : '',
                accountNumber: data.accountNumber ? data.accountNumber : "",
                ifscCode: data.ifscCode ? data.ifscCode : '',
                email: data.email ? data.email : '',
                id: data.id ? data.id : '',
                type: data.type ? { label: data.type, value: data.type } : '',
                accountType: data.accountType ? { label: data.accountType, value: data.accountType } : '',
                editMode:true    
            }, () => {
                this.updateFormErrorState();
                const {panCard, accountHolderName} = this.state;
    
                if(panCard && panCard.length) {
                    this.getImageUrl('Pan')
                }
    
                if(accountHolderName && accountHolderName.length) {
                    this.getImageUrl('Bank')
                }
            })
        }
        
    }

    componentDidMount() {
        this.mounted = true;
        this.filInitialState();
    }

    componentWillReceiveProps(nextProps) {
    if (this.props.updateSelection && JSON.stringify(this.props.updateSelection) != JSON.stringify(nextProps.updateSelection)) {
            this.filInitialState();
        }
    }

    fetchMasterData(masterType, masterParentId) {
        fetchVehicleMaster(masterType, masterParentId, 0, 1000).then((res => {
            if (this.mounted) {
                switch (masterType) {
                    case 'city': {
                        this.setState({
                            citiesOptions: res.content.map(data => ({
                                label: data.name,
                                value: data
                            })),
                        }, () => {
                            if (this.props.updateSelection) {
                                let cities = this.props.updateSelection.city;
                                if (cities.length > 0) {
                                    this.setState({
                                        selectedCity: this.state.citiesOptions ? this.state.citiesOptions.filter(city => {
                                            return cities.some(b => city.label === b.name)
                                        }) : []
                                    }, () => {
                                        this.updateFormErrorState()
                                    })
                                }
                            }
                        })
                    }; break;
                }
            }

        })).catch(err => {
            if ((err.status === 401 || err.message === 'Invalid token')) {
                this.props.onComplete(MessageType.ERROR, ("Invalid token"));
            } else if (this.mounted) {
                this.setState({ citiesOptions: [] });
                this.props.initiateNotification({
                    type: 'error',
                    message: err.description ? err.description : "Error while fetching cities"
                });
            }
        }
        );
    }

    fetchIdTypeData() {
        fetchIdType().then((res) => {
            if (this.mounted) {
                this.setState({
                    idOptions: res.map(data => ({
                        label: data,
                        value: data
                    })),
                }, () => {
                    if (this.props.updateSelection) {
                        let idType = this.props.updateSelection.idType;
                        if(idType) {
                            this.getImageUrl('Id')
                        }
                        this.setState({
                            idType: this.state.idOptions ? this.state.idOptions.find(val => {
                                return val.value === idType
                            }) : ''
                        }, () => {
                            this.updateFormErrorState()
                        })

                    }
                })
            }
        }).catch(err => {
            if ((err.status === 401 || err.message === 'Invalid token')) {
                this.props.onComplete(MessageType.ERROR, ("Invalid token"));
            } else if (this.mounted) {
                this.setState({ idOptions: [] });
                this.props.initiateNotification({
                    type: 'error',
                    message: err.description ? err.description : "Error while fetching cities"
                });
            }
        }
        );
    }

    /**
        *handles the operator type change
        * @param value
        */
    handleSelectChange(key, value) {
        this.setState({ [key]: value }, () => {
            this.updateFormErrorState();
        });
    }
    /**
     *on city change 
     *
     */
    onCityChange(value) {
        this.setState({
            selectedCity: value,
        }, () => {
            this.updateFormErrorState()
        });
    }

    isValidName(sFileName) {
        var _validFileExtensions = SUPPORTED_TYPE;
        if (sFileName.length > 0) {
            var blnValid = false;
            for (var j = 0; j < _validFileExtensions.length; j++) {
                var sCurExtension = _validFileExtensions[j];
                if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
                    blnValid = true;
                    break;
                }
            }
            return blnValid;
        }
    }


    browserSupportFileUpload() {
        var isCompatible = false;
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            isCompatible = true;
        }
        return isCompatible;
    }

    onFileChange(evt, fieldName) {
        this.setState({ invalidJsonId: false, invalidJsonAc: false, invalidJsonPan: false });
        if (!this.browserSupportFileUpload()) {
            alert('The File APIs are not fully supported in this browser!');
        } else {
            var file = evt.target.files[0];

            // this.formData = new FormData();
            // this.formData.append('file', file, file.name);
            this.setState({
                [fieldName] : file
            },()=> {
                this.updateFormErrorState()
            })
        }
    }

    verify(action,type) {
        this.setState({ loading: true });
        action(this.state.id, true).then(()=> {
            this.setState({ loading: false });
            this.props.onVerifyFetchOpertor();
            this.props.initiateNotification({
                type: MessageType.SUCCESS,
                message: `${type} verification done successfully`
            });
        }).catch((err) => {
            this.setState({ loading: false });
            this.props.onComplete(MessageType.ERROR, (err.description ? err.description : "Error occurred "));
        });
    }

    getImageUrl(docType){
        fetchImgaes(this.state.id, docType).then((res)=> {
            if(res.status === 200) {
                res.blob().then((blob)=> {
                    this.setState({
                        ['proof'+docType]: URL.createObjectURL(blob)
                    }, ()=> {
                        this.updateFormErrorState()
                    })
                })
            }
        })
        // return `${BASE_URL}api/master/operator/downloadFile?operatorId=${this.state.id}&docType=${docType}`
    }

    getIdProofClassName = ()=>{
        if(this.state.editMode){
            return 'control-label col-sm-3';
        }
        return "control-label col-sm-3 form-label";
    }

    render() {
        return (
            <div className="row">
                <form className="form-horizontal">
                    <div>
                        <div className="col-sm-12 subheading">Basic Details</div>
                        <div className="col-sm-12"><hr /></div>
                        <div className="col-sm-12">
                            <div className="col-sm-6">

                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label"><span>Operator</span></label>
                                    <div className="col-sm-9 ">
                                        <input type="text" autoComplete="off" className="form-control" id="operator"
                                            placeholder="Enter Operator"
                                            onChange={(value) => {
                                                this.setState({ operator: value.target.value.trim() }, () => {
                                                    this.updateFormErrorState()
                                                })
                                            }}
                                            readOnly={this.props.updateSelection && !this.isAdmin}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.name : ""}
                                        />
                                        {!this.state.validOperatorName && <span className="error-text">Invalid name</span>}

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label"><span>Type</span>
                                    </label>
                                    <div className="col-sm-9">


                                        {!this.props.showInfo ?
                                            <Select
                                                closeOnSelect={!this.state.stayOpen}
                                                disabled={this.state.disabled}
                                                onChange={(value) => {
                                                    this.handleSelectChange('type', value)
                                                }}
                                                options={this.state.operatorTypeOptions}
                                                placeholder="Select"
                                                clearable={false}
                                                rtl={this.state.rtl}
                                                value={this.state.type}
                                            />
                                            :
                                            this.props.updateSelection.type}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label"><span>City</span></label>
                                    <div className="col-sm-9">
                                        {
                                            <Select
                                                multi
                                                closeOnSelect={!this.state.stayOpen}
                                                onChange={(value) => {
                                                    this.onCityChange(value)
                                                }}
                                                options={this.state.citiesOptions}
                                                placeholder="Select City"
                                                rtl={this.state.rtl}
                                                value={this.state.selectedCity}
                                                removeSelected={this.state.removeSelected}
                                            />}
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label"><span>Phone No</span></label>
                                    <div className="col-sm-9">

                                        <input type="text" className="form-control" id="phone"
                                            placeholder="Enter Phone Number"
                                            autoComplete="off"
                                            maxLength="10"
                                            onChange={(value) => {
                                                this.setState({ phone: value.target.value.trim() }, () => {
                                                    this.updateFormErrorState()
                                                })
                                            }}
                                            onKeyPress={(value) => {
                                                this.preventAlphaCharacter(value, value.key);
                                            }}
                                            onPaste={(value) => {
                                                this.preventAlphaCharacter(value, value.nativeEvent.clipboardData.getData('Text'));
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.phone : ""}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label">Email Id</label>
                                    <div className="col-sm-9 ">
                                        <input type="text" className="form-control" id="email"
                                            placeholder="Enter Email ID"
                                            autoComplete="off"
                                            onChange={(value) => {
                                                this.setState({ email: value.target.value.trim() }, () => {
                                                    this.updateFormErrorState()
                                                })
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.email : ""}
                                        />
                                        {!this.state.validEmail && <span className="error-text">Invalid Email</span>}

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label">Address</label>
                                    <div className="col-sm-9 ">

                                        <input type="text" className="form-control" id="address"
                                            placeholder="Enter Address"
                                            onChange={(value) => {
                                                this.setState({ address: value.target.value.trim() })
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.address : ""}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label">No of buses</label>
                                    <div className="col-sm-9 ">

                                        <input type="text" className="form-control" id="noOfBuses"
                                            autoComplete="off"
                                            placeholder="Enter no of buses"
                                            onChange={(value) => {
                                                this.setState({ noOfBuses: value.target.value.trim() })
                                            }}
                                            onKeyPress={(value) => {
                                                this.preventAlphaCharacter(value, value.key);
                                            }}
                                            onPaste={(value) => {
                                                this.preventAlphaCharacter(value, value.nativeEvent.clipboardData.getData('Text'));
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.noOfBuses : ""}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label">GST Number</label>
                                    <div className="col-sm-9 ">

                                        <input type="text" className="form-control" id="gstCertificate"
                                            autoComplete="off"
                                            placeholder="Enter Gst Number"
                                            maxLength="15"
                                            onChange={(value) => {
                                                this.setState({ gstCertificate: value.target.value.trim() }, () => {
                                                    this.updateFormErrorState()

                                                })
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.gstCertificate : ""}

                                        />
                                        {!this.state.validGst && <span className="error-text">Invalid Gst Number</span>}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="col-sm-12 subheading">Identity Details</div>
                        <div className="col-sm-12"><hr /></div>
                        {this.isAdmin && this.state.id && (this.state.proofId && typeof this.state.proofId==="string") && this.props.updateSelection && !this.props.updateSelection.approved && this.props.updateSelection.idType && <div className="col-sm-12 approve-text"><span className="error-text"> {'>>'} Please verify that details entered below are correct</span> <a onClick={()=>this.verify(verifyId,"Identity Details")} onKeyDown={() => {}}>Verify</a></div>}
                        {this.state.form && this.state.form.idError && <div className="col-sm-12 approve-text"><span className="error-text">All Details Required!</span></div>}
                        <div className="col-sm-12">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label"><span>Id Type</span></label>
                                    <div className="col-sm-9">

                                        {!this.props.showInfo ?
                                            <Select
                                                closeOnSelect={!this.state.stayOpen}
                                                disabled={this.props.updateSelection ? this.props.updateSelection.approved : this.state.disabled}
                                                onChange={(value) => {
                                                    this.handleSelectChange('idType', value)
                                                    this.updateFormErrorState()
                                                }}
                                                options={this.state.idOptions}
                                                placeholder="Select"
                                                clearable={false}
                                                rtl={this.state.rtl}
                                                value={this.state.idType}
                                            />
                                            :
                                            this.props.updateSelection.idType}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label"><span>Id Number</span></label>
                                    <div className="col-sm-9 ">
                                        <input type="text" autoComplete="off" className="form-control" id="idNumber"
                                            placeholder="Enter Id Number"
                                            disabled={!this.state.idType}
                                            maxLength={this.state.idType ? idLengthLimit[this.state.idType.value] : null}
                                            onChange={(value) => {
                                                this.setState({ idNumber: value.target.value.trim() }, () => {
                                                    this.updateFormErrorState()
                                                })
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.idNumber : ""}
                                            readOnly={this.props.updateSelection && this.props.updateSelection.approved}

                                        />
                                        {!this.state.validId && <span className="error-text">Invalid Id Number</span>}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className={this.getIdProofClassName()}><span>Id Proof</span></label>
                                    <div className="col-sm-9 file-upload" onChange={(e) => {
                                        if (e.target.files.length > 1) {
                                            this.setState({ invalidJsonId: true, errorMessageId: 'please select max 1 file' });
                                            e.preventDefault();
                                        } else if (e.target.files.length && !this.isValidName(e.target.files[0].name)) {
                                            this.setState({
                                                invalidJsonId: true,
                                                errorMessageId: `Sorry,${e.target.files[0].name} is invalid, allowed extension is ${SUPPORTED_TYPE}`
                                            });
                                            e.preventDefault();
                                        } else if (e.target.files.length) {

                                            this.setState({ invalidJsonId: false });
                                            this.onFileChange(e, 'proofId');
                                        }
                                    }}
                                    >
                                        <input type="file" accept={SUPPORTED_TYPE} multiple="false" disabled={ (this.props.updateSelection && this.props.updateSelection.approved) || !this.state.idType }/>
                                        {this.state.proofId && <img className="preview-img" onError={(e)=>{e.target.style.display='none'}} src={this.state.proofId}/>}
                                    </div>
                                    {this.state.invalidJsonId &&
                                        <div className="message-box" style={{ color: 'red' }}>{this.state.errorMessageId}</div>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="col-sm-12 subheading">PAN Details</div>
                        <div className="col-sm-12"><hr /></div>
                        {this.isAdmin && this.state.id && (this.state.proofPan && typeof this.state.proofPan==="string") && this.props.updateSelection && !this.props.updateSelection.approvePan && this.props.updateSelection.panCard && <div className="col-sm-12 approve-text"><span className="error-text"> {'>>'} Please verify that details entered below are correct</span> <a onClick={()=>this.verify(verifyPAN,"PanCard")} onKeyDown={() => {}}>Verify</a></div>}
                        {this.state.form && this.state.form.panError && <div className="col-sm-12 approve-text"><span className="error-text">All Details Required!</span></div>}
                        <div className="col-sm-12">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label">Pan Card</label>
                                    <div className="col-sm-9 ">

                                        <input type="text" className="form-control" id="panCard"
                                            autoComplete="off"
                                            placeholder="Enter Pan Number"
                                            maxLength="10"
                                            onChange={(value) => {
                                                this.setState({ panCard: value.target.value.trim() }, () => {
                                                    this.updateFormErrorState()
                                                })
                                            }}

                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.panCard : ""}
                                            readOnly={this.props.updateSelection && this.props.updateSelection.approvePan}


                                        />
                                        {!this.state.validPancard && <span className="error-text">Invalid Pan Card Number</span>}

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label">Pan Card Proof</label>
                                    <div className="col-sm-9 file-upload" onChange={(e) => {
                                        if (e.target.files.length > 1) {
                                            this.setState({ invalidJsonPan: true, errorMessagePan: 'please select max 1 file' });
                                            e.preventDefault();
                                        } else if (e.target.files.length && !this.isValidName(e.target.files[0].name)) {
                                            this.setState({
                                                invalidJsonPan: true,
                                                errorMessagePan: `Sorry,${e.target.files[0].name} is invalid, allowed extension is ${SUPPORTED_TYPE}`
                                            });
                                            e.preventDefault();
                                        } else if (e.target.files.length) {

                                            this.setState({ invalidJsonPan: false });
                                            this.onFileChange(e, 'proofPan');
                                        }
                                    }}
                                    >
                                        <input type="file" accept={SUPPORTED_TYPE} multiple="false" disabled={(this.props.updateSelection && this.props.updateSelection.approvePan) || !this.state.panCard}/>
                                        {this.state.proofPan && <img className="preview-img" onError={(e)=>{e.target.style.display='none'}} src={this.state.proofPan}/>}
                                    </div>
                                    {this.state.invalidJsonPan &&
                                        <div className="message-box" style={{ color: 'red' }}>{this.state.errorMessagePan}</div>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="col-sm-12 subheading">Bank Details</div>
                        <div className="col-sm-12"><hr /></div>
                        {this.isAdmin && this.state.id && (this.state.proofBank && typeof this.state.proofBank==="string"  && this.state.proofBank.length > 0) && this.props.updateSelection && !this.props.updateSelection.approveBank && this.props.updateSelection.accountHolderName && <div className="col-sm-12 approve-text"><span className="error-text"> {'>>'} Please verify that details entered below are correct</span> <a onClick={()=>this.verify(verifyBank,"Bank Details")} onKeyDown={() => {}}>Verify</a></div>}
                        {this.state.form && this.state.form.bankError && <div className="col-sm-12 approve-text"><span className="error-text">All Details Required!</span></div>}
                        <div className="col-sm-12">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label">Account Holder Name</label>
                                    <div className="col-sm-9 ">

                                        <input type="text" className="form-control" id="accountHolderName"
                                            autoComplete="off"
                                            placeholder="Enter Holder Name"
                                            onChange={(value) => {
                                                this.setState({ accountHolderName: value.target.value.trim() },()=> {
                                                    this.updateFormErrorState()
                                                });
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.accountHolderName : ""}
                                            readOnly={this.props.updateSelection && this.props.updateSelection.approveBank}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label">Account Number</label>
                                    <div className="col-sm-9 ">

                                        <input type="text" className="form-control" id="accountNumber"
                                            autoComplete="off"
                                            placeholder="Enter Account Number"

                                            onChange={(value) => {
                                                this.setState({ accountNumber: value.target.value.trim() },()=> {
                                                    this.updateFormErrorState()
                                                })
                                            }}
                                            onKeyPress={(value) => {
                                                this.preventAlphaCharacter(value, value.key);
                                            }}
                                            onPaste={(value) => {
                                                this.preventAlphaCharacter(value, value.nativeEvent.clipboardData.getData('Text'));
                                            }}
                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.accountNumber : ""}
                                            readOnly={(this.props.updateSelection && this.props.updateSelection.approveBank) || !this.state.accountHolderName}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label"> Account Type</label>
                                    <div className="col-sm-9">

                                        {!this.props.showInfo ?
                                            <Select
                                                closeOnSelect={!this.state.stayOpen}
                                                disabled={(this.props.updateSelection ? this.props.updateSelection.approveBank : this.state.disabled) || !this.state.accountHolderName}
                                                onChange={(value) => {
                                                    this.handleSelectChange('accountType', value)
                                                }}
                                                options={this.state.accountTypeOptions}
                                                placeholder="Select"
                                                clearable={false}
                                                rtl={this.state.rtl}
                                                value={this.state.accountType}

                                            />
                                            :
                                            this.props.updateSelection.accountType}
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label">IFSC Code</label>
                                    <div className="col-sm-9 ">

                                        <input type="text" className="form-control" id="ifscCode"
                                            autoComplete="off"
                                            placeholder="Enter IFSC Code"
                                            maxLength="11"
                                            onChange={(value) => {
                                                this.setState({ ifscCode: value.target.value.trim() }, () => {
                                                    this.updateFormErrorState()

                                                })
                                            }}

                                            defaultValue={this.props.updateSelection ? this.props.updateSelection.ifscCode : ""}
                                            readOnly={(this.props.updateSelection && this.props.updateSelection.approveBank) || !this.state.accountHolderName}

                                        />
                                        {!this.state.validIfsc && <span className="error-text">Invalid Ifsc Code</span>}

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-sm-12">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="name7"
                                        className="control-label col-sm-3 form-label">Account Detail Proof</label>
                                    <div className="col-sm-9 file-upload" onChange={(e) => {
                                        if (e.target.files.length > 1) {
                                            this.setState({ invalidJsonAc: true, errorMessageAc: 'please select max 1 file' });
                                            e.preventDefault();
                                        } else if (e.target.files.length && !this.isValidName(e.target.files[0].name)) {
                                            this.setState({
                                                invalidJsonAc: true,
                                                errorMessageAc: `Sorry,${e.target.files[0].name} is invalid, allowed extension is ${SUPPORTED_TYPE[0]}`
                                            });
                                            e.preventDefault();
                                        } else if (e.target.files.length) {

                                            this.setState({ invalidJsonAc: false });
                                            this.onFileChange(e, 'proofBank');
                                        }
                                    }}
                                    >
                                        <input type="file" accept={SUPPORTED_TYPE} multiple="false" disabled={(this.props.updateSelection && this.props.updateSelection.approveBank) || !this.state.accountHolderName}/>
                                        {this.state.proofBank && <img className="preview-img" onError={(e)=>{e.target.style.display='none'}} src={this.state.proofBank}/>}
                                    </div>
                                    {this.state.invalidJsonAc &&
                                        <div className="message-box" style={{ color: 'red' }}>{this.state.errorMessageAc}</div>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-12 form-view">
                        <Button onClick={() => this.props.onClose()}>Close</Button>
                        <Button bsStyle="primary"
                            onClick={() => {
                                this.setState({ loading: true })
                                this.fetchAddUpdate()
                            }}
                            disabled={this.state.form.invalid || this.state.loading}
                        >{this.state.loading ? 'Saving...' : 'Save changes'}</Button>
                    </div>
                </form>
            </div>)
    }

    /**
     * validates trip form data
     * @param target
     */
    updateFormErrorState() {
        let formState = validateOperator(this.state);
        this.setState({
            form: formState
        }, () => {
            this.state.form.emailError ? this.setState({
                validEmail: false
            }) : this.setState({
                validEmail: true
            })
            this.state.form.gstError ? this.setState({
                validGst: false
            }) : this.setState({
                validGst: true
            })
            this.state.form.ifscError ? this.setState({
                validIfsc: false
            }) : this.setState({
                validIfsc: true
            })
            this.state.form.panCardError ? this.setState({
                validPancard: false
            }) : this.setState({
                validPancard: true
            })
            this.state.form.operatorNameError ? this.setState({
                validOperatorName: false
            }) : this.setState({
                validOperatorName: true
            })
            this.state.form.idNumberError ? this.setState({
                validId: false
            }) : this.setState({
                validId: true
            })

        }

        );
    }

    preventAlphaCharacter(event, value) {
        const regex = validationRegex.number
        this.preventDefaultEvent(event, value, regex)
    }

    preventSpecialCharacter(event, value) {
        const regex = validationRegex.alphanumeric
        this.preventDefaultEvent(event, value, regex)
    }

    preventDefaultEvent(event, value, re) {
        if (!re.test(value)) {
            event.preventDefault();
        }
    }

    /**
     * Used to Add trip
     */
    fetchAddUpdate() {
        let data = {
            name: this.state.operator.toUpperCase(),
            city: this.state.selectedCity.map((city) => {
                return city.value
            })
        }
        data['phone'] = this.state.phone ? this.state.phone : null;
        data['email'] = this.state.email ? this.state.email : null;
        data['address'] = this.state.address ? this.state.address : null;
        data['noOfBuses'] = this.state.noOfBuses ? this.state.noOfBuses : null;
        data['gstCertificate'] = this.state.gstCertificate ? this.state.gstCertificate : null;
        data['idNumber'] = this.state.idNumber ? this.state.idNumber : null;
        data['panCard'] = this.state.panCard ? this.state.panCard : null;
        data['accountHolderName'] = this.state.accountHolderName ? this.state.accountHolderName : null;
        data['accountNumber'] = this.state.accountNumber ? this.state.accountNumber : null;
        data['ifscCode'] = this.state.ifscCode ? this.state.ifscCode : null;
        data['idType'] = this.state.idType ? this.state.idType.value : null;
        data['type'] = this.state.type ? this.state.type.value : null;
        data['accountType'] = this.state.accountType ? this.state.accountType.value : null;
        // data['proofId'] = this.state.proofId ? this.state.proofId : null;
        // data['proofPan'] = this.state.proofPan ? this.state.proofPan : null;
        // data['proofBank'] = this.state.proofBank ? this.state.proofBank : null;


        if (this.props.updateSelection) {
            data['id'] = this.state.id
        }
        this.formData = new FormData();
        this.formData.append('operatorDto', JSON.stringify(data));
        if(this.state.proofId && typeof this.state.proofId !== 'string') {
            this.formData.append('proofId', this.state.proofId);
        }

        if(this.state.proofPan && typeof this.state.proofPan !== 'string') {
            this.formData.append('proofPan', this.state.proofPan);
        }

        if(this.state.proofBank && typeof this.state.proofBank !== 'string') {
            this.formData.append('proofBank', this.state.proofBank);
        }

        createMasterData('operator', this.formData, this.props.updateSelection).then((response) => {
            if (this.mounted) {
                this.setState({ loading: false });
                this.props.onComplete(MessageType.SUCCESS, "Successfully done ");
            }
        }).catch((err) => {
            this.props.onComplete(MessageType.ERROR, (err.description ? err.description : "Error occurred "));
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }
}

export default connect(null, defaultMapDispatchToProps)(OperatorCreate);