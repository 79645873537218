import React from "react";
import { withRouter } from "react-router-dom";
import ChaloButton from "../../../../shared/widgets/chaloButton";
import { AuthenticationService } from "../../../../services/authorization";
import PropTypes from "prop-types";

const SuccessScreen = (props) => {
    const { history, location } = props;
    const authService = AuthenticationService.getInstance();
    let redirectUrl = authService.isManager() ? "/vehicleMasterView/camera" : "/vehicleMaster/camera";
    // Correctly access state from location and provide default values
    const { message = "Success!", buttonText = "Okay"} = location.state || {};

    const navigateBack = () => {
        history.push(redirectUrl);
    };

    return (
        <div className="camera-success row">
            <div className="camera-success-message">
                <h2>{message}</h2>
            </div>
            <div className="col-md-12 camera-success-footer">
                <ChaloButton buttonText={buttonText} onClick={navigateBack} />
            </div>
        </div>
    );
};

SuccessScreen.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default withRouter(SuccessScreen);
