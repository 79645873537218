import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const ChaloLoader = ({ loadingGifSrc, altText, backdropStyle }) => (
    <div className="loading-backdrop" style={backdropStyle}>
        <img src={loadingGifSrc} alt={altText} className="loading-gif" />
    </div>
);

ChaloLoader.defaultProps = {
    loadingGifSrc: '../../../../assets/images/chalo_loading.gif', // ensure correct file extension (.gif)
    altText: 'Loading...',
    backdropStyle: {}
};

ChaloLoader.propTypes = {
    loadingGifSrc: PropTypes.string,
    altText: PropTypes.string,
    backdropStyle: PropTypes.object,
};

export default ChaloLoader;
