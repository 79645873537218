import React, { useRef, useState, useEffect, useCallback } from 'react';
import "./cameraOnBoarding.css";
import QrScanner from "qr-scanner";
import ChaloTopNav from '../../../../shared/widgets/chaloTopNav';
import ChaloAlertDialog from '../../../../shared/widgets/chaloAlertDialog';
import ChaloLoader from '../../../../shared/widgets/chaloLoader';
import { fetchLiveFeedDetails } from '../../../../services/vehicleMasterService';
import { AuthenticationService } from '../../../../services/authorization';
import PropTypes from 'prop-types';

const ScanCameraQr = ({ history }) => {
    const videoEl = useRef(null);
    const qrBoxEl = useRef(null);
    const scannerRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [errorHeading, setErrorHeading] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const authService = AuthenticationService.getInstance();

    const isCameraIdValid = (id) => /^\d{10}$/.test(id.trim());

    const extractCameraId = (qrValue) => {
        const match = qrValue.match(/\d{10}$/);
        return match ? match[0] : null;
    };

    const getFailureReason = (data) => {
        return { 
            heading: 'Error', 
            message: data && data.description 
                        ? data.description 
                        : data && data.error 
                        ? data.error 
                        : 'An unknown error occurred' 
        };
    };

    // Handler for successful QR scan.
    const onScanSuccess = useCallback((result) => {
        setIsLoading(true);
        const cameraId = result.data;
        if (scannerRef.current) {
            scannerRef.current.stop();
        }
        let extractedCameraId = extractCameraId(cameraId)
        if (extractedCameraId && isCameraIdValid(extractedCameraId)) {
            getLiveFeedDetails(extractedCameraId);
        } else {
            setIsLoading(false);
            setErrorHeading('Wrong Qr Code');
            setErrorMessage('Try scanning again to add the camera');
            setShowModal(true);
        }
    }, [history]);

    const getLiveFeedDetails = async (cameraId) => {
        try {
            const data = await fetchLiveFeedDetails(cameraId);
            handleLiveFeedResponse(data, cameraId);
        } catch (error) {
            let failureReason;
            if(error && error.status === 400){
                let combinedData = { liveFeed: false, cameraId };
                let path = authService.isManager() ? '/vehicleMasterView/cameraDetails': '/vehicleMaster/cameraDetails';
                history.push(path, combinedData);                    
                return;
            }
            if (error && typeof error === 'object') {
                failureReason = getFailureReason(error);
            } else {
                failureReason = { heading: 'Network Error', message: 'There was a problem connecting to the API.' };
            }
            setErrorHeading(failureReason.heading);
            setErrorMessage(failureReason.message);
            setShowModal(true);
            setIsLoading(false);
            if (scannerRef.current) {
                scannerRef.current.stop();
            }
        }
    }

    const handleLiveFeedResponse = (data, cameraId) => {
        setIsLoading(false);
        const pathBase = authService.isManager() ? '/vehicleMasterView' : '/vehicleMaster';
        
        if (data.cameraConnected) {
            const combinedData = { ...data, cameraId };
            history.push(`${pathBase}/liveFeedPreview`, { feedData: combinedData });
        } else {
            const combinedData = { liveFeed: false, cameraId };
            history.push(`${pathBase}/cameraDetails`, combinedData);
        }
    };

    const onScanFail = useCallback((err) => {
        console.log('QR Scan error:', err);
    }, []);

    function getCameraErrorResponse(errorCode) {
        let heading;
        
        switch (errorCode) {
            case "ERROR_CONNECT_DEVICE":
                heading = "Device Connection Error";
                break;
            case "ERROR_WRONG_DEVICE_ID":
                heading = "Invalid Device ID";
                break;
            case "ERROR_LOGIN_FAILURE":
                heading = "Authentication Failure";
                break;
            case "ERROR_OPERATION_TIMEOUT":
                heading = "Timeout Error";
                break;
            default:
                heading = "Unknown Error";
        }
        
        return {heading: heading};
    }  



    const closeModal = useCallback(() => {
        setShowModal(false);
        try {
        if (scannerRef.current) {
            scannerRef.current.start().catch((err) => console.error('Error restarting scanner:', err));
        }
        } catch (error) {
        console.error('Error in closeModal:', error);
        }
    }, []);

    useEffect(() => {
        if (videoEl.current && !scannerRef.current) {
        scannerRef.current = new QrScanner(videoEl.current, onScanSuccess, {
            onDecodeError: onScanFail,
            preferredCamera: 'environment',
            highlightScanRegion: true,
            highlightCodeOutline: true,
            overlay: qrBoxEl.current || undefined,
        });
        scannerRef.current.start()
            .catch((err) => {
            console.error('Error starting QR scanner:', err);
            });
        }
        return () => {
        if (scannerRef.current) {
            scannerRef.current.stop();
        }
        };
    }, [onScanSuccess, onScanFail]);

    return (
        <div className='scanner-container row'>
        <ChaloTopNav navContent="QR Code Scanner" />
        {isLoading && <ChaloLoader />}
        <div className="qr-reader">
            <video ref={videoEl} className="full-screen-video" autoPlay></video>
            <p className='scan-brief'>Align the QR code within the frame to scan</p>
        </div>
        {showModal && (
            <div className="error-dialog-container">
            <ChaloAlertDialog 
                heading={errorHeading} 
                message={errorMessage} 
                onClose={closeModal} 
            />
            </div>
        )}
        </div>
    );
};
ScanCameraQr.propTypes = {
    history: PropTypes.object,
};

export default ScanCameraQr;
