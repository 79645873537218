import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import scanQr from '../../../../assets/images/scan-qr.svg';
import './cameraOnBoarding.css';
import ChaloButton from '../../../../shared/widgets/chaloButton';
import ChaloAlertDialog from '../../../../shared/widgets/chaloAlertDialog';
import ChaloInputField from '../../../../shared/widgets/chaloInputField';
import ChaloLoader from '../../../../shared/widgets/chaloLoader';
import { fetchLiveFeedDetails } from '../../../../services/vehicleMasterService';
import { AuthenticationService } from '../../../../services/authorization';
import PropTypes from 'prop-types';

const getFailureReason = (data) => {
    if (data && data.description) {
        return { heading: 'Error', message: data.description };
    } else if (data && data.error) {
        return { heading: 'Error', message: data.error };
    } else {
        return { heading: 'Error', message: 'An unknown error occurred' };
    }
};

function getCameraErrorResponse(errorCode) {
    let heading;
    switch (errorCode) {
        case "ERROR_CONNECT_DEVICE":
            heading = "Device Connection Error";
            break;
        case "ERROR_WRONG_DEVICE_ID":
            heading = "Invalid Device ID";
            break;
        case "ERROR_LOGIN_FAILURE":
            heading = "Authentication Failure";
            break;
        case "ERROR_OPERATION_TIMEOUT":
            heading = "Timeout Error";
            break;
        default:
            heading = "Unknown Error";
    }
    return { heading: heading };
}

const isCameraIdValid = (number) => /^\d{10}$/.test(number.trim());

const QRSection = React.memo(({ onScanClick }) => (
    <div className="scan-qr-content">
        <img src={scanQr} alt="Scan QR Code" className="img-responsive" />
        <p>Scan QR code placed on the camera</p>
        <ChaloButton buttonText="Scan QR Code" onClick={onScanClick} />
    </div>
));

QRSection.propTypes = {
    onScanClick: PropTypes.func,
};

const ManualInputForm = ({ cameraId, onInputChange, onSubmit }) => {
    const isButtonDisabled = !isCameraIdValid(cameraId);
    return (
        <div className="camera-id-form">
            <p>Or enter the camera ID manually</p>
            <div className="id-input-field">
                <ChaloInputField
                    
                    label="Camera ID"
                    placeholder="Camera ID"
                    name="cameraID"
                    value={cameraId}
                    onChange={onInputChange}
                    id="camera-id"
                />
            </div>

            <ChaloButton
                buttonText="Continue"
                onClick={onSubmit}
                disabled={isButtonDisabled}
            />
        </div>
    );
};

ManualInputForm.propTypes = {
    cameraId: PropTypes.string,
    onInputChange: PropTypes.func,
    onSubmit: PropTypes.func,
};

const ErrorDialog = ({ heading, message, onClose }) => (
    <div className="error-dialog-container">
        <ChaloAlertDialog heading={heading} message={message} onClose={onClose} />
    </div>
);
ErrorDialog.propTypes = {
    heading: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
};

const CreatingCamera = ({ history }) => {
    const [cameraId, setCameraId] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorHeading, setErrorHeading] = useState('');
    const [feedData, setFeedData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const authService = AuthenticationService.getInstance()

    const handleNavigation = () => {
        const path = authService.isManager() 
            ? '/vehicleMasterView/scanCameraQr' 
            : '/vehicleMaster/scanCameraQr';
    
        history.push(path);
    };
    

    const handleInputChange = useCallback((event) => {
        setCameraId(event.target.value);
    }, []);

    const handleCameraIdSubmit = async () => {
        setIsLoading(true);
        try {
            const data = await fetchLiveFeedDetails(cameraId);
            if (data.cameraConnected) {
                setIsLoading(false);
                let combinedData = { ...data, cameraId };
                setFeedData(combinedData);
                let path = authService.isManager() ? "/vehicleMasterView/liveFeedPreview" : "/vehicleMaster/liveFeedPreview"
                history.push(path, { feedData: combinedData });
            } else {
                setIsLoading(false);

                let combinedData = { liveFeed: false, cameraId };
                let path = authService.isManager() ? "/vehicleMasterView/cameraDetails" : "/vehicleMaster/cameraDetails"
                history.push(path, combinedData);                    
                return;
                
            }
        } catch (error) {
            setIsLoading(false);
            handleFetchError(error, cameraId);
        }
    };
    const redirectToCameraDetails = (cameraId, basePath) => {
        const combinedData = { liveFeed: false, cameraId };
        history.push(`${basePath}/cameraDetails`, combinedData);
    };

    const handleFetchError = (error, cameraId) => {
        if (error && error.status === 400) {
            redirectToCameraDetails(cameraId, authService.isManager() ? '/vehicleMasterView' : '/vehicleMaster');
            return;
        }
    
        const failureReason = (error && typeof error === 'object')
            ? getFailureReason(error)
            : { heading: 'Network Error', message: 'There was a problem connecting to the API.' };
    
        setErrorHeading(failureReason.heading);
        setErrorMessage(failureReason.message);
        setShowModal(true);
    };

    const closeModal = useCallback(() => {
        setShowModal(false);
        setErrorMessage('');
        setErrorHeading('');
    }, []);

    return (
        <div className="row">
            {isLoading && <ChaloLoader />}
            <div className="col-md-12">
                <div className="add-camera-container">
                    <h3>Camera Onboarding</h3>
                    <div className="add-camera-content">
                        <QRSection onScanClick={handleNavigation} />
                        <hr className="custom-hr" />
                        <ManualInputForm
                            cameraId={cameraId}
                            onInputChange={handleInputChange}
                            onSubmit={handleCameraIdSubmit}
                        />
                    </div>
                </div>
                {showModal && (
                    <ErrorDialog
                        heading={errorHeading}
                        message={errorMessage}
                        onClose={closeModal}
                    />
                )}
            </div>
        </div>
    );
};

CreatingCamera.propTypes = {
    history: PropTypes.object,
};

export default withRouter(CreatingCamera);
