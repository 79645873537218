import React from "react";
import "./index.css";

const ChaloDropDown = ({
    label,
    name = "",
    options = [],
    value = "",
    onChange,
    placeholder = "Select an option",
    disabled = false,
}) => {
    return (
        <div className={`cDropdown-container ${disabled ? "cDropdown-field-disabled" : ""}`}>
            {label && value && (
                <label className="cDropdown-label">
                    {label} <span className="cRequired">*</span>
                </label>
            )}
            <select
                name={name}
                className={`cDropdown-field ${!value ? "cText-grey" : "cSelect-position"}`}
                value={value}
                onChange={onChange}
                disabled={disabled}
            >
                <option value="" disabled className="cDisplay-none">
                    {placeholder}
                </option>
                {options.length > 0 ? (
                options.map((option) => (
                    <option key={option.value} value={option.value}>
                    {option.label}
                    </option>
                ))
                ) : (
                    <option disabled>No options available</option>
                )}
            </select>
            <i className="fa fa-angle-down cDropdown-arrow"></i>
        </div>
    );
};

export default ChaloDropDown;