import React from "react";
import { connect } from "react-redux";
import { ButtonToolbar,Modal } from "react-bootstrap";
import {
  Paginator,
  Search as SearchBar,
  DatePicker,
} from "../../../../shared/widgets";
import { SelectFilter } from "../../../../shared/widgets/selectFilter/selectFilter";

import { PageWrapper, TableWrapper } from "../../../../shared/layout";
import { getPanicReport,getRegionsForPanicReport } from "../../../../services/vehicleMasterService.js";
import { AuthenticationService } from "../../../../services/authorization";
import { templateStrings,MessageType } from "../../../../Constant.js";
import { defaultMapDispatchToProps } from "../../../../redux/map-to-props";
import * as moment from "moment";
import "./index.css";
import {MapWrapperComponent} from "../../map/mapWrapper";
import {setSelectePanicAlertRow} from '../../../../redux/actions/alert-list.js'

class PanicReport extends React.Component {
  constructor(props) {
    super(props);
    this.authService = AuthenticationService.getInstance();
    let m = moment(new Date());
    let email = localStorage.getItem("userName");
    this.state = {
      searchString: "",
      email: email,
      showHeaderButton: true,
      pageNo: 0,
      type: this.props.match.params.typeId,
      totalPages: 0,
      isUserAViewer: false,
      placeHolder: "Search",
      showStatusChangeConfirmModal: false,
      selectedDate: m.subtract(0, "day"),
      showModal:false,
      selectedAlert:null,
      selectedRegion:"test",
      isUserComingFirstTime:false
    };
    this.supportedTypes = [".xlsx"];
    this.getList = this.getList.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
  }
  
  /**
   * Called when the component is mounted.
   * Fetches the city names list.
   */
  componentDidMount() {
    this.mounted = true;
    this.fetchRegionsForPanicReport();
  }

  fetchPanicReport = () => {
    this.props.updateLoader(true);
    getPanicReport(this.state)
      .then((res) => {
        this.props.updateLoader(false);
        this.setState({
          content: res.items,
          totalPages: res.totalPages,
          pageNo: res.currentPage,
          totalItems: res.totalItems,
        });
      })
      .catch((err) => {
        this.props.updateLoader(false);
        this.setState({
          content: [],
          totalPages: 0,
          pageNo: 0,
          totalItems: 0,
        });
        this.errorHandler(err);
      });
  };

  dateChange = (date) => {
    let startDate = parseInt(date.startOf("day").format("x"));
    let endDate = parseInt(date.endOf("day").format("x")) + 1;
    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
        selectedDate: date,
        pageNo: 0,
      },
      () => {
        this.fetchPanicReport();
      }
    );
  };

  errorHandler(err) {
    if (err.status === 401 || err.message === "Invalid token") {
      this.props.setTokenExpired(true);
    } else if (this.mounted) {
      this.setState({ cities: [] });
      this.props.initiateNotification({
        type: "error",
        message: err.description
          ? err.description
          : "Error while fetching Panic report",
      });
    }
  }

  handleRegionChange(region) {
    this.setState((prevState) => {
      let startDate = parseFloat(
        prevState.selectedDate.startOf("day").format("x")
      );
      let endDate = parseFloat(prevState.selectedDate.endOf("day").format("x")) + 1;
      return {
        selectedRegion: region,
        pageNo: 0,
        startDate,
        endDate,
      };
    }, () => {
      this.fetchPanicReport();
    });
  }

  handleSearchChange(e) {
    this.setState({ searchString: e, pageNo: 0 }, () => {
      this.fetchPanicReport();
    });
  }

  /**
   * API call to get devices.
   * @param pageNo current page number
   */
  getList(pageNo) {
    if (pageNo >= 0 && pageNo <= this.state.totalPages) {
      this.setState({ pageNo }, () => {
        this.fetchPanicReport();
      });
    }
  }

  /**
   * Used to show device in row in List view
   * @param item  Object of item to display
   */
  displayList(item) {
    const getTableCelContent = (column) => {
      switch (column) {
        case "timestamp":
          return moment(item[column]).format("DD/MM/YYYY hh:mm:ss A");
        case "lastPacketArriveAt":
          return moment(item[column]).format("hh:mm:ss A");
        default:
          return item[column];
      }
    };
    function generateUniqueString() {
      const array = new Uint8Array(16);
      crypto.getRandomValues(array);
      return Array.from(array, (value) =>
        ("0" + value.toString(16)).slice(-2)
      ).join("");
    }
    return (
      <tr key={generateUniqueString()}>
        {Object.keys(templateStrings.panicAlertReport).map((column) => {
          return (
            <td key={generateUniqueString()} title={getTableCelContent(column)}>
              {column !== 'action' && getTableCelContent(column)}
              {column === 'action' && <div className="actions">
                <span onClick={()=>{this.setState({showModal:true,selectedAlert:item})}} onKeyDown={() => {}}>
                  <i className="fa fa-eye protocol" aria-hidden="true"/>
                </span>
                
                <span onClick={()=>{
                  this.props.setSelectePanicAlertRow({fromTime:this.state.startDate,toTime:this.state.endDate,searchString:this.state.searchString,pageNo:this.state.pageNo,selectedRegion:this.state.selectedRegion})
                  this.props.history.push(`/alerts/panic-alerts/${item["deviceId"]}`,[{fromTime:this.state.startDate,toTime:this.state.endDate}]);
                }} onKeyDown={() => {}}>
                  <i className="fa fa-history protocol" aria-hidden="true" />
                </span>
                </div>}
            </td>
          );
        })}
      </tr>
    );
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate() {
    if (this.props.notificationRegion) {
      const selectedRegion = this.props.notificationRegion;
      this.setState({selectedRegion,isUserComingFirstTime:true,selectedDate:moment(new Date())},()=>{
        this.handleRegionChange(selectedRegion);
      })
      this.props.updateNotificationRegion(null);
    }else if(!this.state.isUserComingFirstTime && this.props.selectedAlertRow){
        const {selectedRegion,fromTime,toTime,searchString,pageNo} = this.props.selectedAlertRow;
        this.setState({selectedRegion,startDate:fromTime,endDate:toTime,searchString,pageNo,isUserComingFirstTime:true,selectedDate:moment(new Date(fromTime))},()=>{
          this.handleRegionChange(selectedRegion);
        });
        this.props.setSelectePanicAlertRow(null);
    }
  }

  close = ()=>{
    this.setState({
      showModal:false,
      selectedAlert:null
    })
  }

  displayModal = () => {
    if(this.state.showModal){
    const data = this.state.selectedAlert;
    const center = {
      lat: data.latitude,
      lng: data.longitude
    }
    const imgUrl = `${window.location.protocol}//gps.chalo.com/assets/images`;
    const icon = {
      url: `${imgUrl}/device.png`,
      scaledSize: {
          width: 40,
          height: 57
      }
    }

    return (
      <Modal
        show={this.state.showModal}
        onHide={this.close.bind(this)}
        backdrop={false}
        dialogClassName="info-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Panic Alert Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="detail-container-1">
              <div className="col-sm-5">
              {Object.keys(templateStrings.panicAlertReport).map((key)=>{
              return (<div className="row" key={key}>
                { key != 'action' && <>
                  <span className="info-value" > {templateStrings.panicAlertReport[key]} : </span>
                  { key === 'timestamp' && <span className="info-label"> &nbsp;&nbsp;  {moment(this.state.selectedAlert[key]).format("DD/MM/YYYY hh:mm:ss A")}</span>}
                  { key === 'lastPacketArriveAt' && <span className="info-label"> &nbsp;&nbsp;  {moment(this.state.selectedAlert[key]).format("hh:mm:ss A")}</span>}
                  {!['timestamp','lastPacketArriveAt'].includes(key) && <span className="info-label"> &nbsp;&nbsp;  {this.state.selectedAlert[key]}</span>}
                  </>
                }
              </div>)})}
              </div>
              <div className="col-sm-7 map-wrapper">
                            <div className="row device-detail-map">
                                <MapWrapperComponent data={data} center={center} icon={icon}/>
                            </div>
              </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
  }

  capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  fetchRegionsForPanicReport() {
    getRegionsForPanicReport()
        .then((res) => {
          if (res && res.items) {
            const orderedNewOptions = res.items.sort((a, b) => {
              if (a.regionId > b.regionId) {
                return 1;
              } else if (b.regionId > a.regionId) {
                return -1;
              } else {
                return 0;
              }
            });

            this.setState({
              regionOptions: orderedNewOptions.map((data) => {
                    return { label: this.capitalize(data.regionId), value: data.regionId }
                })
            })
          }else{
            this.setState({ regionOptions: [] });
          }
          if(!this.state.isUserComingFirstTime){
            this.handleRegionChange("agra");
            this.setState({selectedRegion:"agra"})
          }
        })
        .catch((err) => {
          if (err.status === 401 || err.message === "Invalid token") {
            this.props.onComplete(MessageType.ERROR, "Invalid token");
          } else if (this.mounted) {
            this.props.onComplete(
              MessageType.ERROR,
              err.description
                ? err.description
                : "Error occurred in while getting data for regions"
            );
          }
        });
  }

  render() {
    return (
      <div>
        <PageWrapper.Header heading="Panic Alert">
          {this.state.content && (
            <span className="count pl-5"> Count : {this.state.totalItems}</span>
          )}
          <div
            className="dataTables_length wrapper-gps"
            id="dataTables-example_length"
          >
            <ButtonToolbar>
              <DatePicker
                date={this.state.selectedDate}
                onDateChange={this.dateChange}
                withoutDefaultBorder={false}
                todaySelectable={true}
              />
            </ButtonToolbar>
          </div>
          <div className="pull-right search-container">
            
            <SearchBar
              placeHolder={this.state.placeHolder}
              onSearchChange={this.handleSearchChange}
              value={this.state.searchString}
            />
            <span
              className="span-icon refresh-icon"
              onClick={this.fetchPanicReport}
              onKeyDown={() => {}}
            ></span>
          </div>
        </PageWrapper.Header>
        <PageWrapper.Body>
          <PageWrapper.BodyHeader>
            <div className="panic-alert-row">
              <div className="panic-alert-col-1">Filter By:</div>
              <div className="panic-alert-col-2">
                <SelectFilter
                  value={this.state.selectedRegion}
                  type='city'
                  onChange={(value) => {
                    if(!value){
                      value = {value:''}
                    }
                    this.handleRegionChange(value.value);
                  }}
                  valueKey="value"
                  labelKey="label"
                  clearable={true}
                  placeholder="Select Region"
                  options={this.state.regionOptions}
                  />
              </div>
            </div>
          </PageWrapper.BodyHeader>
          <TableWrapper>
            <TableWrapper.Header columns={templateStrings.panicAlertReport} />
            {
              <tbody>
                {this.state.content &&
                  this.state.content.map((data) => {
                    return this.displayList(data);
                  })}
                {(!this.state.content ||
                  (this.state.content && this.state.content.length == 0)) && (
                  <tr className="gradeA odd noDataStyle" role="row">
                    <td className="sorting_1" colSpan="6">
                      No Data Found !
                    </td>
                  </tr>
                )}
              </tbody>
            }
          </TableWrapper>
          <Paginator
            pageNo={this.state.pageNo}
            totalPages={this.state.totalPages}
            getList={this.getList}
          />
        </PageWrapper.Body>
        {this.displayModal()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    notificationRegion: state.Notification.notificationRegion,
    selectedAlertRow: state.Alert.selectedAlertRow
  };
}

const mapDispatchToProps = (dispatch, state) => (
  {
      ...defaultMapDispatchToProps(dispatch, state),
      setSelectePanicAlertRow: value => {
          dispatch(setSelectePanicAlertRow(value))
      }
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(PanicReport);
