// import {fetchToken} from "./login/loginService";
import Cookies from 'universal-cookie';
import { decryptString } from "./authorization/Authorization"
import config from "../config";
import { AuthenticationService } from "../services/authorization";
import { fetchToken } from "../services/login/loginService";
import store from "../redux/createStore";
import {setTokenExpired} from "../redux/actions/authActions";

export function fetchWrapper(url, options) {
    return getValidToken().then((token) => {
        options.headers ? (options.headers.Authorization = 'Bearer ' + token) : (
            options.headers = {
                Authorization: 'Bearer ' + token
            });
        return fetch(url, options)
            .then(res => handleResponseInJson(res, url, options), handleNetworkError).then(handleTextResponse);
    }).catch((err) => {
        throw err;
    })
}

/**
 * Handel text response , throw error if catches error in json parsing
 * @param responseText
 */
function handleTextResponse(responseText) {
    try {
        const parsedJSON = JSON.parse(responseText);
        return parsedJSON;
    } catch (err) {
        throw { status: 200, description: responseText };
    }
}

export function fetchWrapperWithoutToken(url, options) {
    return fetch(url, options)
        .then(res => handleResponseInJson(res, url, options), handleNetworkError).then(handleTextResponse);
}

export function fetchWrapperTextResponse(url, options) {
    return fetch(url, options)
        .then((response) => {
            return response.text();
        })
        .then((responseJson) => {
            console.log(responseJson);
        })
        .catch((error) => {
            console.error(error);
        });
}

export function getValidToken() {
    const cookies = new Cookies();
    var decryptedLoginCookie;
    var loginStateCookie = cookies.get(config.cookieKeys.loginState)
    if (loginStateCookie) {
        decryptedLoginCookie = decryptString(loginStateCookie)
    }
    return new Promise((resolve, reject) => {
        if (localStorage.getItem('token') && decryptedLoginCookie !== 'loggedOut' && checkValidUser()) {
            resolve(localStorage.getItem('token'));
        } else {
            reject(new Error("Invalid token"));
        }
    })
}
/**
 * handles fetch response status and return text data,
 * throws error if response is not ok or
 * there  is any issue in parsing response to text
 * @param response
 * @returns {*}
 */
function handleResponseInJson(response, url, options) {
    if (response.ok) {
        if (response.status == 204) {
            return JSON.stringify({
                status: 204, description: "No Content Found"
            });
        }
        else {
            try {
                return response.text();
            } catch (err) {
                throw err;
            }
        }
    } else {
        const originalRequest = { url: url, options: options }
        return response.json().then(function (error) {
            error.status = response.status;
            throw error;
        }).catch((err) => {
            const authInstance = AuthenticationService.getInstance()
            const isREfreshing = authInstance.IsRefreshing;
            if (response.status === 401 && !url.includes('token')) {
                if (!isREfreshing) {
                    authInstance.IsRefreshing = true
                    fetchAccessToken().then((res) => {
                        authInstance.IsRefreshing = false
                        localStorage.setItem('token', res.access_token);
                        localStorage.setItem('refresh_token', res.refresh_token);
                        onAccessTokenFetched(res.access_token)
                        authInstance.setSubscriber([])

                    }).catch((err) => {
                        console.log("refresh error");
                        AuthenticationService.destroyInstance();
                        store.dispatch(setTokenExpired(true))
                    })
                }


                const retryOriginalRequest = new Promise((resolve) => {
                    authInstance.addSubscriber(access_token => {
                        originalRequest.options.headers ? (originalRequest.options.headers.Authorization = 'Bearer ' + access_token) : (
                            originalRequest.options.headers = {
                                Authorization: 'Bearer ' + access_token
                            });
                        resolve(fetch(originalRequest.url, originalRequest.options).then(res => handleResponseInJson(res, url, options), handleNetworkError).then(handleTextResponse))
                    })
                })

                return retryOriginalRequest.then((res) => { return res }).catch(err => { throw err })

            }
            else {
                if (err && !err.description) {
                    err.status = response.status;
                }
                throw err;
            }
        });
    }
}
/**
 * handle network errors
 * @param error
 */
function handleNetworkError(error) {
    throw {
        msg: error.message
    };
}
/**
 * returns final promise which return promise with proper exception handling
 * @param url
 * @param options
 * @returns {Promise}
 */
export function getAPIPromise(url, options) {
    return new Promise((resolve, reject) => {
        fetchWrapper(url, options).then((response) => {

            if (!(HTTP_EXCEPTIONS[response.status] || response.status == 500 || response.status == 204)) {
                resolve(response)
            }
            else {
                reject(response);
            }
        }).catch((err) => {
            if (err.status == 200) {
                resolve(err.description);
            }
            else {
                reject(err);
            }

        })
    })


}

export async function getAPIPromiseWithError(url, options) {
    try {
        const response = await fetchWrapper(url, options);
        return response;
    } catch (err) {
        throw err;
    }
}



function onAccessTokenFetched(access_token) {
    const authInstance = AuthenticationService.getInstance()
    let subscribers = authInstance.getSubscriber()
    subscribers = subscribers.filter(callback => callback(access_token));
    // authInstance.setSubscriber(subscribers)
}

function fetchAccessToken() {
    const refreshToken = localStorage.getItem('refresh_token')
    var details = {
        refresh_token: refreshToken,
        grant_type: 'refresh_token'
    };
    return fetchToken(details)
}

export function checkValidUser() {
    const cookies = new Cookies();
    const authInstance = AuthenticationService.getInstance()
    var decrypteduserCookie;
    var userCookie = cookies.get(config.cookieKeys.user);
    var user=localStorage.getItem('userId')
    if(userCookie){
        decrypteduserCookie = decryptString(userCookie)     
        authInstance.setValidUser(user==decrypteduserCookie)  
        return user==decrypteduserCookie 
    }else{ return true}
}

export const HTTP_EXCEPTIONS = {
    NO_CONTENT: 'NO_CONTENT',
    NOT_ACCEPTABLE: 'NOT_ACCEPTABLE',
    BAD_REQUEST: 'BAD_REQUEST',
    CONFLICT: 'CONFLICT',
    FAILED_DEPENDENCY: 'FAILED_DEPENDENCY',
    NOT_FOUND: 'NOT_FOUND',
    UNAUTHORIZED: 'UNAUTHORIZED',
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
    FORBIDDEN: 'FORBIDDEN',
    NOT_MODIFIED: 'NOT_MODIFIED'
};
