import React from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import {masterRoutes} from '../../../../routing/routes';
import MasterList from './listView/masterList'
import GuardedRoute from '../guradedRoute';
import {userAuthoritiesArray} from '../../../../services/authorization';
import Audit from './auditView/Audit';
import LiveFeedPreview from '../cameraOnBoarding/liveFeedPreview';
import ScanCameraQr from '../cameraOnBoarding/scanCameraQr';
import creatingCamera from '../cameraOnBoarding/creatingCamera';
import CameraDetails from '../cameraOnBoarding/cameraDetails';
import cameraConfirmDetails from '../cameraOnBoarding/cameraConfirmDetails';
import cameraSuccess from '../cameraOnBoarding/cameraSuccess';

export class MasterWrapper extends React.Component {
    render() {
        const {match}=this.props;
        return <Switch>
             <Route exact path={`${match.url}/camera/details/:cameraId`}  component={Audit}/>
             <Route exact path={`${match.url}/addCamera`} component={creatingCamera} />
             <Route exact path={`${match.url}/scanCameraQr`} component={ScanCameraQr} />
             <Route exact path={`${match.url}/livefeedPreview`} component={LiveFeedPreview} />
             <Route exact path={`${match.url}/cameraDetails`} component={CameraDetails} />
             <Route exact path={`${match.url}/cameraConfirmDetails`} component={cameraConfirmDetails} />
             <Route exact path={`${match.url}/cameraSuccess`} component={cameraSuccess} />
             <GuardedRoute path={`${match.url}/:masterType`} component={MasterList} role={userAuthoritiesArray()}  pathname={this.props.location.pathname}/>
            <Redirect from="/" to={`${match.url}/${masterRoutes.DEPOT}`}/>
        </Switch>

    }
}