import React from "react";
import PropTypes from "prop-types";
import './index.css';

const ChaloAlertDialog = ({ heading, message, onClose }) => (
    <div className="error-dialog-content">
        <h3>{heading}</h3>
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
    </div>
);

ChaloAlertDialog.defaultProps = {
    heading: '',
    message: '',
    onClose: () => {}
};

ChaloAlertDialog.propTypes = {
    heading: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func
};

export default ChaloAlertDialog;
