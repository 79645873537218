import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Hls from 'hls.js';
import "./cameraOnBoarding.css";
import ChaloButton from '../../../../shared/widgets/chaloButton';
import ChaloTopNav from '../../../../shared/widgets/chaloTopNav';
import ChaloLoader from '../../../../shared/widgets/chaloLoader';
import { AuthenticationService } from '../../../../services/authorization';

const LiveFeedPreview = ({ match, history }) => {
    const [feedData, setFeedData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState("RTMP feed not available due to server issue.");
    const [isSlowInternet, setSlowInternet] = useState(false);

    const [liveFeed,setLiveFeed] = useState(true); 
    const videoRef = useRef(null);
    const hlsRef = useRef(null);
    const authService = AuthenticationService.getInstance();

    // Retrieve feed data from history state
    useEffect(() => {
        const prevData = history.location &&
                         history.location.state &&
                         history.location.state.feedData;
        if (prevData) {
            setFeedData(prevData);
            setError(null);
        } else {
            setError("No feed data provided.");
        }
        setIsLoading(false);
    }, [history.location]);

    useEffect(() => {
        if (!feedData || !feedData.rtmpFeedUrl){
            return;
        }
        setIsLoading(true);

        if (Hls.isSupported()) {
            const video = videoRef.current
            if (!video) {
                setError("Video element not found.");
                setIsLoading(false);
                return;
            }
            // Initialize HLS instance
            hlsRef.current = new Hls();
            hlsRef.current.attachMedia(video);

            // Once the video element is attached, load the stream source.
            hlsRef.current.on(Hls.Events.MEDIA_ATTACHED, () => {
                console.log("Media attached, loading source:", feedData.rtmpFeedUrl);
                hlsRef.current.loadSource(feedData.rtmpFeedUrl);
            });

            hlsRef.current.on(Hls.Events.MANIFEST_PARSED, () => {
                video.play()
                    .then(() => {
                        setIsLoading(false);
                        setLiveFeed(true)
                    })
                    .catch(err => {
                        console.error("Autoplay prevented:", err);
                        setIsLoading(false);
                        setLiveFeed(false);
                    });
            });

            // Handle errors during streaming.
            hlsRef.current.on(Hls.Events.ERROR, (event, data) => {
                console.error("HLS error:", data);
                if(data.details && data.details === 'bufferStalledError'){
                    setError("RTMP feed not available due to slow internet.");
                    setSlowInternet(true);
                } else {
                    setError("RTMP feed not available due to server issue.");
                }
                setLiveFeed(false);
                setIsLoading(false);
            });

            // Clean up on unmount
            return () => {
                if (hlsRef.current) {
                    hlsRef.current.destroy();
                    hlsRef.current = null;
                }
            };
        } else {
            setError("HLS not supported in this browser.");
            setIsLoading(false);
        }
    }, [feedData]);

    const handleNavigation = useCallback(() => {
        if(isSlowInternet){
            setLiveFeed(true);
        }
        let route = authService.isManager() ? '/vehicleMasterView/cameraDetails' : '/vehicleMaster/cameraDetails';
        history.push(route, { ...feedData,liveFeed });
    }, [history, feedData,liveFeed,isSlowInternet]);

    return (
        
        <div className='camera-wrapper row'>
            {isLoading && <ChaloLoader />}
            <div className="camera-details-container">
                <ChaloTopNav navContent="Required information" />
                <div className="col-md-12 camera-detail-content">
                    <h4 className="feed-heading">RTMP live feed</h4>
                    <div className="w-100 live-feed" style={{ position: 'relative' }}>
                        
                        <video
                            ref={videoRef}
                            className="live-feed-canva"
                            playsInline
                            muted
                            autoPlay
                            controls={false}
                            width="100%"
                        />
                        {!liveFeed && (
                            <div className='canva-failed'>
                                {error}
                            </div>
                        )}
                    </div>
                    {liveFeed ? (
                        <div className="feed-msg feed-success-msg">
                            <i className="fa fa-check-circle"></i>
                            <span>RTMP feed success</span>
                        </div>
                    ) : (
                        <div className="feed-msg feed-failed-msg">
                            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            <span>RTMP feed failed</span>
                        </div>
                    )
                    }
                </div>
                <div className="col-md-12 live-feed-footer">
                    <ChaloButton buttonText="Proceed" onClick={handleNavigation} />
                </div>
            </div>
        </div>
    );
};

LiveFeedPreview.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
};

export default LiveFeedPreview;
